import { GET_ACCOUNT, GET_NFT, GET_SINGLE_NFT } from '../types';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { toast } from 'react-toastify';
import BapeNFT from '../../abi/BapeNFT.json';
const RPC =
	'https://eth-mainnet.alchemyapi.io/v2/5UKBlEWtWPU9zx50yE7CYLK8Am9RMH_6';

// const RPC =
// 	'https://eth-rinkeby.alchemyapi.io/v2/D_wv5i_qKCmMpc1sncGpnQ9sAz7FUK_E';
const chainID = 1;
// const chainID = 4;
const contractAddressNew = '0xE15674568aB1aA7529a8621aDb110a11245e6706';
export let web3 = new Web3(RPC);
export const connectAccount = () => async dispatch => {
	// if (typeof window.ethereum !== "undefined") {
	//   await window.ethereum.enable();
	//   window.web3 = new Web3(window.ethereum);
	//   const web3 = window.web3;
	//   const accounts = await web3.eth.getAccounts();
	//   const chainid = await web3.eth.getChainId();
	//       console.log(chainid);
	//     if(chainid != chainID) {alert("Please connect with Rinkeby Testnet");return;}

	//   //   setAccount(accounts[0]);
	//   // localStorage.setItem("accounts", accounts[0]);
	//   dispatch({ type: GET_ACCOUNT, payload: accounts[0] });
	// }
	try {
		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider,
				options: {
					bridge: 'https://bridge.walletconnect.org',
					chainId: chainID, //137 for mainnet
					network: 'mainnet', //matic for mainnet
					// network: "mumbai", //matic for test net
					rpc: {
						1: RPC,
						// 4: RPC,
						// 80001: "https://polygon-mumbai.g.alchemy.com/v2/zItQRWHRPhns60Fn6m4mLuU08pPjvZrV",
					},
				},
			},
		};
		const web3Modal = new Web3Modal({
			network: 'mainnet', //matic for mainnet
			cacheProvider: false, // optional
			providerOptions, // required
			disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
		});

		console.log('Web3Modal instance is', web3Modal);
		const provider = await web3Modal.connect();
		web3 = new Web3(provider);
		dispatch({ type: 'ADD_WEB3', payload: provider });
		console.log('Web3 instance is', web3, provider);
		const chainid = await web3.eth.getChainId();
		console.log(chainid);
		if (chainid !== chainID)
			return toast.error('Please connect with Ethereum network!', {
				autoClose: 1000,
			});
		const accounts = await web3.eth.getAccounts();
		console.log(accounts);
		console.log('provider', await provider, await provider.connected);
		dispatch({ type: GET_ACCOUNT, payload: accounts[0] });
		if (accounts[0])
			toast.success('wallet connected successfully!', {
				autoClose: 1000,
			});
		provider.on('accountsChanged', accounts => {
			console.log('Account changed', accounts[0]);
			dispatch({ type: GET_ACCOUNT, payload: accounts[0] });
		});
	} catch (err) {
		console.log(err);
	}
};

export const getNftState = nfts => async dispatch => {
	dispatch({ type: GET_NFT, payload: nfts });
};

export const changeAccount = accounts => async dispatch => {
	localStorage.setItem('accounts', accounts[0]);
	dispatch({ type: GET_ACCOUNT, payload: accounts[0] });
};

export const getSingleNft = id => async dispatch => {
	dispatch({ type: GET_SINGLE_NFT, payload: id });
};

export const disconnectAccount = () => async dispatch => {
	dispatch({ type: 'DISCONNECT' });
};

export const loaderState = (bool, message) => async dispatch => {
	dispatch({ type: 'LOAD_STATE', payload: bool, msg: message });
};
