import React from 'react';
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
function RoadMap() {
	return (
		<div className='roadmap__section'>
			<h2 className='text-center Bangers road_map'>ROAD MAP</h2>
			<VerticalTimeline>
				<VerticalTimelineElement
					className='vertical-timeline-element--work'
					iconStyle={{
						background: 'black',
						color: 'black',
					}}
				>
					<h5 className='road_map__phase'>PHASE ONE</h5>
					<p className='road_map__subtitle'>
						Minting & The Beginning of the BAPES Exclusive Club
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>1. </strong>
						Being a holder of BAPES grants you access to an
						exclusive members only club. This includes giveaways,
						staking earnings, exclusive merch, airdrops, IRL events,
						early access to future NFT drops and more
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>2. </strong>
						OG List minting
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>3. </strong>
						White List minting
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>4. </strong>
						Public minting
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>5. </strong>
						Establishing the BAPES fund community wallet. The BAPES
						community fund wallet will be used towards building the
						BAPES culture. We will spend it on where the community
						needs it most. Transactions will be completely
						transparent, which all holders can see.
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>a. </strong>
						22.3% of mint automatically goes to the community wallet
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>b. </strong>
						40% of royalties will go to the community wallet
					</p>
					<p className='text__primary'>
						After selling out; we move to phase two, three, four,
						five, and six
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className='vertical-timeline-element--work'
					iconStyle={{
						background: 'black',
						color: 'black',
					}}
				>
					<h5 className='road_map__phase'>PHASE TWO</h5>
					<p className='road_map__subtitle'>
						Celebrating with the community
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>1. </strong>
						BAPES will immediately be listed on: Rarity.tools,
						Rarity Sniper, Rarity Sniffer
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>2. </strong>
						Each holder will be able to claim the BAPES Club Card
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>a. </strong>
						This Club Card is made of high quality metal and is
						similar to a credit card
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>b. </strong>
						There are three cards designs which will be based on
						minting roles. OG list minters will receive a black
						card, white list minters will receive a platinum card
						and public minters will receive a gold card
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>3. </strong>
						First edition BAPES merch giveaway to 500 holders that
						are active in the discord will be randomly selected to
						receive an item from the first edition collection
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>4. </strong>
						100 BAPES nft giveaway. For 10 weeks, we are going to
						airdrop BAPES nfts randomly to 10 holders per week that
						are active in the discord community
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					iconStyle={{
						background: 'black',
						color: 'black',
					}}
				>
					<h5 className='road_map__phase'>PHASE THREE</h5>
					<p className='road_map__subtitle'>Entering the Metaverse</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>1. </strong>
						BAPES is going to purchase land in the Sandbox.
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>a. </strong>
						The BAPES community is going to acquire a 3x3 plot of
						land for an official place we can call home for the
						BAPES community.
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>b. </strong>
						The BAPES community is going to hire an architect to
						build out our new land with (huge jungle gym park,
						treehouse mansion, tree house huts, activity event
						arena, outdoor concert stage for events and more
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					iconStyle={{
						background: 'black',
						color: 'black',
					}}
				>
					<h5 className='road_map__phase'>PHASE FOUR</h5>
					<p className='road_map__subtitle'>
						Staking and Merchandise Store
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>1. </strong>
						BAPES $Bananas token launch
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>a. </strong>
						TClosed ecosystem designed to keep $Bananas within the
						BAPES project. BAPES $bananas token can be used to
						purchase BAPES exclusive merchandise and can be used in
						the Metaverse to purchase asset item upgrades and other
						benefits
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>2. </strong>
						Deployment of BAPES staking site with built-in rarity
						tool ranking system. Staking earnings are based off
						BAPES NFT rarity ranking; the higher the rankings, the
						higher the earnings
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>3. </strong>
						The launch of the holders’ access-only exclusive
						merchandise store
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>a. </strong>
						he merch store can only be accessed by BAPES holders
						simply by connecting their wallets to the site (not
						accessible to the public) where the holder can access
						BAPES gear, BAPES clothing, canvas art, figurine art and
						more unique creations all designed after BAPES from
						artists around the world
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className='vertical-timeline-element--education'
					iconStyle={{
						background: 'black',
						color: 'black',
					}}
				>
					<h5 className='road_map__phase'>PHASE FIVE</h5>
					<p className='road_map__subtitle'>
						Bringing the community together in celebration
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>1. </strong>
						BAPES is going to host an IRL event to celebrate and
						unite the community
					</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>a. </strong>
						IRL event will be held in the USA
					</p>
				</VerticalTimelineElement>
				<VerticalTimelineElement
					className='vertical-timeline-element--education'
					iconStyle={{
						background: 'black',
						color: 'black',
					}}
				>
					<h5 className='road_map__phase'>PHASE SIX</h5>
					<p className='road_map__subtitle'>The Next Big Move</p>
					<p className='road_map__desc'>
						<strong className='road_map__number'>1. </strong>
						BAPES is going to airdrop a special surprise to all
						holders that will take us to the next level in the
						Metaverse.
					</p>
				</VerticalTimelineElement>
			</VerticalTimeline>
			{/* <Container className='mt-5'>
				
							
						<div className='road__map__card'>
							
						</div>
					</Col>
				</Row>
			</Container> */}
		</div>
	);
}

export default RoadMap;
