import { useEffect, useState, useRef } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Hero from './components/Hero';
import RoadMap from './components/RoadMap';
import TheTeam from './components/TheTeam';
import UniqueBapes from './components/UniqueBapes';
import { useDispatch, useSelector } from 'react-redux';
import {
	disconnectAccount,
	connectAccount,
} from './store/actions/accountActions';
import Web3 from 'web3';
import BapeNFT from './abi/BapeNFT.json';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';

const contractAddress = '0xE15674568aB1aA7529a8621aDb110a11245e6706';

function App() {
	const roadmapRef = useRef(null);
	const artistRef = useRef(null);
	const [price, setPrice] = useState(0);
	const { account, web3Provider } = useSelector(state => state.accounts);
	const dispatch = useDispatch();

	useEffect(() => {
		if (window.ethereum) {
			window.ethereum.on('accountsChanged', newAccount => {
				if (newAccount.length) {
					dispatch(connectAccount());
				} else {
					dispatch(disconnectAccount());
				}
			});
		}
	}, [window.ethereum]);
	const roadMapScroll = () => roadmapRef.current.scrollIntoView();
	const artistScroll = () => artistRef.current.scrollIntoView();
	return (
		<div className='App'>
			<Header
				account={account}
				web3Provider={web3Provider}
				singlePrice={price}
				roadMapScroll={roadMapScroll}
				artistScroll={artistScroll}
			/>
			{/* <Hero /> */}
			<UniqueBapes />
			<div ref={roadmapRef}>
				<RoadMap />
			</div>
			<div ref={artistRef}>
				<TheTeam />
			</div>
			<Footer />
		</div>
	);
}

export default App;
