import React from 'react';
import { Button } from 'reactstrap';
import Discord from '../assets/imgs/discord.png';
import Instagram from '../assets/imgs/instagram.png';
function Footer() {
	return (
		<div className='bg__primary text-center p-3'>
			<a href='https://discord.gg/bapesofficial' target='_blank'>
				<img
					src={Discord}
					alt='Discord'
					height='64px'
					className='pointer'
				/>
			</a>
			<a href='https://mobile.twitter.com/bapesofficial' target='_blank'>
				<Button
					className='rounded-circle mx-3 px-3 border-0'
					style={{ height: '64px', background: '#000000' }}
				>
					<i className='fab fa-twitter text__primary fa-2x'></i>
				</Button>
			</a>
			<a href='https://www.instagram.com/bapes.official' target='_blank'>
				<img
					src={Instagram}
					alt='Instagram'
					height='64px'
					className='pointer'
				/>
			</a>
		</div>
	);
}

export default Footer;
