import { GET_ACCOUNT, GET_NFT, GET_SINGLE_NFT } from '../types';

const initialState = {
	account: null,
	nfts: [],
	single: {},
	web3Provider: null,
	loaderOpen: false,
	message: '',
};
const accountReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_ACCOUNT:
			return {
				...state,
				account: action.payload,
			};
		case 'ADD_WEB3':
			return {
				...state,
				web3Provider: action.payload,
			};
		case GET_NFT:
			return {
				...state,
				nfts: action.payload,
			};
		case GET_SINGLE_NFT:
			let singleState = state.nfts.find(
				nf => nf.tokenId === action.payload
			);
			return {
				...state,
				single: singleState,
			};
		case 'DISCONNECT':
			localStorage.removeItem('accounts');
			return {
				...state,
				account: null,
			};
		case 'LOAD_STATE':
			return {
				...state,
				loaderOpen: action.payload,
				message: action.msg,
			};
		default:
			return state;
	}
};

export default accountReducer;
