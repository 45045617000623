import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import HeroBape from '../assets/imgs/hero.png';
function Hero() {
	return (
		<>
			<div className='overflow-hidden'>
				<img src={HeroBape} alt='HeroBape' className='hero__img' />
			</div>
			<Container fluid className='ms-0 ms-lg-3 mt-5'>
				<Row>
					<Col>
						<h2 className='next__thing'>
							The Next <br className='d-none d-md-block' /> Big
							Thing
						</h2>
						<h1 className='bapes'>BAPES</h1>
						<h4 className='about__us'>ABOUT US</h4>
						<Col>
							<p className='intro'>
								We are a 3D fully rendered
								<br /> 8k resolution NFT collection of 8,888.
								<br />
								<br />
								Our inspiration comes from the{' '}
								<br className='d-none d-sm-block' /> late 80's
								and early 90's street fashion{' '}
								<br className='d-none d-sm-block' /> styles,
								with also mixing in some current{' '}
								<br className='d-none d-sm-block' /> high end
								fashion styles.
							</p>
						</Col>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Hero;
