import React, { useState, useEffect } from 'react';
import {
	Navbar,
	NavbarBrand,
	Collapse,
	Nav,
	NavItem,
	NavLink,
	Button,
	Modal,
	ModalBody,
	Input,
	Form,
	Container,
	Row,
	Col,
	Spinner,
} from 'reactstrap';
import Logo from '../assets/imgs/bapes.png';
import Discord from '../assets/imgs/discord.png';
import Instagram from '../assets/imgs/instagram.png';
import { toast } from 'react-toastify';
import {
	connectAccount,
	loaderState,
	web3,
} from '../store/actions/accountActions';
import { useDispatch, useSelector } from 'react-redux';
// import Web3 from 'web3';
import BapeNFT from '../abi/BapeNFT.json';
import { fileHashes } from './Hashes';
// const RPC = 'https://rinkeby.infura.io/v3/156a2e19bd1347648c5832fd7bb32d54';

const contractAddress = '0xE15674568aB1aA7529a8621aDb110a11245e6706';
const contractAddressNew = '0xE15674568aB1aA7529a8621aDb110a11245e6706';

function Header({ artistScroll, roadMapScroll }) {
	const [isOpen, setIsOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [hashes, setHashes] = useState([]);
	const [tokenData, setTokenData] = useState([]);
	const [imageUrls, setImageUrls] = useState([]);
	const [nftValue, setNftValue] = useState(1);
	const [nftPrice, setNftPrice] = useState(0);
	const [currentNftPrice, setCurrentNftPrice] = useState(0);
	const [priceInEther, setPriceInEther] = useState(0);
	const [priceLoader, setPriceLoader] = useState(false);
	const dispatch = useDispatch();
	const { account, web3Provider, loaderOpen, message } = useSelector(
		state => state.accounts
	);
	const toggle = () => {
		setIsOpen(!isOpen);
	};
	const connect = () => {
		dispatch(connectAccount());
	};

	const stateLoader = (bool, message) => {
		if (!bool) {
			setModalOpen(false);
		}
		dispatch(loaderState(bool, message));
	};

	const duplicateHashCheck = async contract => {
		let random =
			fileHashes[Math.floor(Math.random() * fileHashes.length)].hash;
		const hashCheck = await contract.methods.hashes(random).call();
		console.log(hashCheck, '==>', random);
		if (hashCheck == 1) {
			duplicateHashCheck(contract);
		} else {
			return random;
		}
	};
	const minusValue = () => {
		if (nftValue > 0) {
			setNftValue(nftValue - 1);
			let newValue = (nftPrice * (nftValue - 1)).toString();
			let obj = web3.utils.fromWei(newValue, 'ether');
			setPriceInEther(obj);
		}
	};
	const addValue = () => {
		if (nftValue < 4) {
			setNftValue(nftValue + 1);
			let newValue = (nftPrice * (nftValue + 1)).toString();
			let obj = web3.utils.fromWei(newValue, 'ether');
			setPriceInEther(obj);
		}
	};

	const mintNFT = async () => {
		try {
			const bapeContract = new web3.eth.Contract(
				BapeNFT,
				contractAddressNew
			);
			console.log('contract', bapeContract);
			let price = 0;

			stateLoader(true, 'Please wait While transaction is in progress');

			const ogList = await bapeContract.methods.ogList(account).call();
			const whiteList = await bapeContract.methods
				.whitelist(account)
				.call();
			let teamList = await bapeContract.methods.teamList(account).call();
			const simplePrice = await bapeContract.methods.price().call();
			console.log('price list', ogList, whiteList, teamList, simplePrice);
			if (ogList > 0) {
				let ogListPrice = await bapeContract.methods
					.staffPrice()
					.call();
				console.log('New staff price build');
				console.log('ogListPrice', ogListPrice);
				for (let i = 0; i < nftValue; i++) {
					if (i <= ogList) {
						price += Number(ogListPrice);
					} else {
						price += Number(simplePrice);
					}
				}
				// price = ogListPrice;
				// price = ogList;
			} else if (whiteList > 0) {
				let whiteListPrice = await bapeContract.methods
					.whitelistedPrice()
					.call();
				for (let i = 0; i < nftValue; i++) {
					if (i <= whiteList) {
						price += Number(whiteListPrice);
					} else {
						price += Number(simplePrice);
					}
				}
				// price = whiteListPrice;
			} else if (teamList > 0) {
				let teamListPrice = await bapeContract.methods.teamPrice.call();
				for (let i = 0; i < nftValue; i++) {
					if (i <= teamList) {
						price += Number(teamListPrice);
					} else {
						price += Number(simplePrice);
					}
				}
				// price = teamListPrice;
			} else {
				for (let i = 0; i < nftValue; i++) {
					price += Number(simplePrice);
				}
				// price = simplePrice;
			}
			console.log('simple price', price);
			if (nftValue < 2) {
				const randomHash =
					fileHashes[Math.floor(Math.random() * fileHashes.length)]
						.hash;
				// const randomHash =await duplicateHashCheck(bapeContract);

				// let image = `https://ipfs.io/ipfs/${randomHash}`;
				// let metaDataObject = {
				// 	name: 'Bape',
				// 	description: 'Bape NFTs Description',
				// 	external_url: '',
				// 	image: randomHash,
				// 	hash: randomHash,
				// };
				// const metaData = JSON.stringify(randomHash);
				const mintMyNFT = await bapeContract.methods
					.createNFT(randomHash, randomHash)
					.send({ from: account, value: price.toString() })
					.on('confirmation', res => {
						stateLoader(false, '');
						// toggle();
						console.log('transaction confirmed', res);
					})
					.on('error', err => {
						stateLoader(false, '');
						// toggle();
						toast.error(err.message);
						console.log('Error', err);
					});
			} else {
				mintBatchNFT(price);
			}
		} catch (err) {
			console.log(err);
			toast.error(err.message, {
				autoClose: 1000,
			});
		}
	};

	const mintBatchNFT = async price => {
		try {
			const bapeContract = new web3.eth.Contract(
				BapeNFT,
				contractAddressNew
			);

			stateLoader(true, 'Please wait While transaction is in progress');
			const hashArr = [];
			const metadatArr = [];
			for (let i = 0; i < nftValue; i++) {
				let randomHash =
					fileHashes[Math.floor(Math.random() * fileHashes.length)]
						.hash;
				// const randomHash = await duplicateHashCheck(
				// 	bapeContract
				// );

				// let image = `https://ipfs.io/ipfs/${randomHash}`;
				// let metaDataObject = {
				// 	name: 'Bape',
				// 	description: 'Bape NFTs Description',
				// 	external_url: '',
				// 	image: randomHash,
				// 	hash: randomHash,
				// };
				const metaData = randomHash;

				setHashes([...hashes, randomHash]);
				hashArr.push(randomHash);
				setTokenData([...tokenData, metaData]);
				metadatArr.push(randomHash);
				// setImageUrls([...imageUrls, image]);
			}
			console.log('batch [rice', price, bapeContract);
			console.log('new build');
			console.log('hashArr', hashArr);
			console.log('metadatArr', metadatArr);
			const responseData = await bapeContract.methods
				.batchMinting(hashArr, metadatArr)
				.send({
					from: account,
					value: price,
				})
				.on('confirmation', () => {
					stateLoader(false, '');
					// toggle();

					console.log('transaction confirmed');
				})
				.on('error', err => {
					stateLoader(false, '');
					// toggle();
					toast.error(err.message);
					console.log('Error', err.message);
				});
		} catch (err) {
			console.log(err);
			toast.error(err.message, {
				autoClose: 1000,
			});
		}
	};
	const modalToggle = () => {
		setModalOpen(!modalOpen);
	};
	const getPrice = async () => {
		console.log('in get price', web3);
		setPriceLoader(true);
		const bapeContract = new web3.eth.Contract(BapeNFT, contractAddressNew);
		const ogList = await bapeContract.methods.ogList(account).call();
		const whiteList = await bapeContract.methods.whitelist(account).call();
		const teamList = await bapeContract.methods.teamList(account).call();
		const simplePrice = await bapeContract.methods.price().call();
		console.log('all prices', ogList, whiteList, teamList, simplePrice);
		if (ogList > 0) {
			let ogListPrice = await bapeContract.methods.ogListAmount().call();
			setNftPrice(ogListPrice);
			setCurrentNftPrice(ogListPrice);
			let newValue = ogListPrice.toString();
			let obj = web3.utils.fromWei(newValue, 'ether');
			setPriceInEther(obj);
			setPriceLoader(false);
		} else if (whiteList > 0) {
			let whiteListPrice = await bapeContract.methods
				.whitelistedPrice()
				.call();
			setNftPrice(whiteListPrice);
			setCurrentNftPrice(whiteListPrice);
			let newValue = whiteListPrice.toString();
			let obj = web3.utils.fromWei(newValue, 'ether');
			setPriceInEther(obj);
			setPriceLoader(false);
		} else if (teamList > 0) {
			let teamListPrice = await bapeContract.methods.teamPrice().call();
			setNftPrice(teamListPrice);
			setCurrentNftPrice(teamListPrice);
			let newValue = teamListPrice.toString();
			let obj = web3.utils.fromWei(newValue, 'ether');
			setPriceInEther(obj);
			setPriceLoader(false);
		} else {
			setNftPrice(simplePrice);
			setCurrentNftPrice(simplePrice);
			let newValue = simplePrice.toString();
			let obj = web3.utils.fromWei(newValue, 'ether');
			setPriceInEther(obj);
			setPriceLoader(false);
		}
	};

	useEffect(() => {
		if (account) {
			getPrice();
		}
	}, [account]);
	return (
		<>
			<div className='header'>
				<Navbar expand='md' light className='px-3'>
					<NavbarBrand href='/'>
						<img src={Logo} alt='Logo' className='logo' />
					</NavbarBrand>

					<i
						className='fa fa-bars fa-2x d-block d-md-none ms-3'
						onClick={toggle}
					></i>
					{/* <NavbarToggler className= onClick={function noRefCheck() {}} /> */}
					<Collapse navbar isOpen={isOpen}>
						<Nav className='ms-auto text-center' navbar>
							<NavItem>
								<NavLink>Home</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={roadMapScroll}>
									Roadmap
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink onClick={artistScroll}>Team</NavLink>
							</NavItem>
							<NavItem>
								<Button
									className='btn__mint text-nowrap ms-0 ms-md-2  mt-1'
									onClick={modalToggle}
									disabled={!account}
								>
									Mint
								</Button>
							</NavItem>
							<NavItem>
								<Button
									className='btn__mint ms-0 ms-md-2 mt-1'
									onClick={connect}
									disabled={account}
								>
									{account
										? account.substr(0, 5) +
										  '...' +
										  account.substr(account.length - 5)
										: 'Connect'}
								</Button>
							</NavItem>
							<hr className='d-block d-md-none' />
							<div className='d-flex d-md-none justify-content-center'>
								<a
									href='https://discord.gg/bapesofficial'
									target='_blank'
								>
									<img
										src={Discord}
										alt='Discord'
										height='48px'
										className='pointer'
									/>
								</a>
								<a
									href='https://mobile.twitter.com/bapesofficial'
									target='_blank'
								>
									<Button
										className='rounded-circle mx-2 px-3 border-0'
										style={{
											height: '50px',
											background: '#000000',
										}}
									>
										<i className='fab fa-twitter text__primary font-subtitle'></i>
									</Button>
								</a>
								<a
									href='https://www.instagram.com/bapes.official'
									target='_blank'
								>
									<img
										src={Instagram}
										alt='Instagram'
										height='48px'
										className='pointer'
									/>
								</a>
							</div>
						</Nav>
					</Collapse>
				</Navbar>
				<Container fluid className='ps-3 mt-5'>
					<Row>
						<Col>
							<h2 className='next__thing'>
								The Next <br className='d-none d-md-block' />{' '}
								Big Thing
							</h2>
							<h1 className='bapes'>BAPES</h1>
							<h4 className='about__us'>ABOUT US</h4>
							<Col>
								<p className='intro'>
									We are a 3D fully rendered
									<br /> 8k resolution NFT collection of
									8,888.
									<br />
									<br />
									Our inspiration comes from the{' '}
									<br className='d-none d-sm-block' /> late
									80's and early 90's street fashion{' '}
									<br className='d-none d-sm-block' /> styles,
									with also mixing in some current{' '}
									<br className='d-none d-sm-block' /> high
									end fashion styles.
								</p>
							</Col>
						</Col>
					</Row>
				</Container>
			</div>

			<Modal centered isOpen={modalOpen}>
				<ModalBody className='border2 bg-black'>
					<Button
						className='bg-transparent btn__cancel border-0'
						onClick={modalToggle}
					>
						<i class='fas fa-times-circle fa-2x text__primary'></i>
					</Button>
					<Form
						onSubmit={e => {
							e.preventDefault();
							mintNFT();
						}}
					>
						<h2 className='text-white Bangers'>MINT NOW</h2>
						<div className='d-flex mt-3'>
							<Button
								disabled={nftValue === 1}
								onClick={minusValue}
								className='bg-transparent border-0'
							>
								<i class='fas fa-minus-circle fa-2x text__primary'></i>
							</Button>

							<Input
								min={1}
								max={4}
								type='number'
								required
								value={nftValue}
								onChange={e => {
									setNftValue(e.target.value);
									if (e.target.value > 0) {
										let newValue = (
											nftPrice * e.target.value
										).toString();
										console.log(
											'newValue',
											newValue,
											nftPrice
										);
										let obj = web3.utils.fromWei(
											newValue,
											'ether'
										);
										console.log('price changed', obj);
										setPriceInEther(obj);
									}
								}}
								className='custom__input'
							/>
							<Button
								onClick={addValue}
								className='bg-transparent border-0'
							>
								<i className='fas fa-plus-circle fa-2x text__primary'></i>
							</Button>
						</div>
						<div className='d-flex Bangers mt-5'>
							<h5 className='text-white'>Total Price</h5>

							<h5
								id='currentPrice'
								className='text-white ms-auto'
							>
								{priceLoader ? (
									<Spinner
										className='custom_spinner'
										size='sm'
									/>
								) : (
									`${priceInEther} ETH`
								)}
							</h5>
						</div>
						<Button
							type='submit'
							className='mt-3 border-0 text-black font-title Bangers bg__primary'
							block
							disabled={priceLoader}
						>
							MINT NOW
						</Button>
					</Form>
				</ModalBody>
			</Modal>
			<Modal centered isOpen={loaderOpen}>
				<ModalBody className='border2 bg-black'>
					<div className='d-flex justify-content-center'>
						<h1>
							<Spinner size='lg' />
						</h1>
					</div>
					<h2>{message}</h2>
				</ModalBody>
			</Modal>
		</>
	);
}

export default Header;
