import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import Yoni from '../assets/imgs/Yoni.jpg';
import Justin from '../assets/imgs/Justin.jpg';
import Steve from '../assets/imgs/steve.jpeg';
import Jay from '../assets/imgs/jay.png';
import Metaspark from '../assets/imgs/metaspark.png';
import Kings from '../assets/imgs/kings.png';
import Evol from '../assets/imgs/evol.png';
import Ryu from '../assets/imgs/ryu.png';
import Childsy from '../assets/imgs/childsy.png';
import Scotti from '../assets/imgs/scoty.png';
function TheTeam() {
	return (
		<div className='mt-5 pb-5'>
			<Container>
				<h1 className='text__primary Bangers text-center the__team'>
					THE TEAM
				</h1>
				<Row className='justify-content-center'>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='bg__primary border-0 h-100'>
							<img
								src={Justin}
								alt='Justin'
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									Justin Jeffries Nitijus (39)
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									Founder
								</h3>
								<h4 className='text-nowrap Mont-Regular mt-3 text-center'>
									Windermere, Florida USA
								</h4>
								<br />
								<p className='mb-5'>
									Early developer and crypto investor; crypto
									research analyst, financial planner and
									marketing specialist.
									<br />
									<br />
									"I started coding in 1998 and caught the dot
									com boom at the end, which led to moving
									into web development, later becoming a
									specialist in SEO and internet marketing.{' '}
									<br /> <br /> I hold two degrees; I
									graduated in 2005 from UCF with my BA in
									Psychology and later earned my BBA. <br />
									<br /> I'm constantly evolving with the
									development of blockchain and
									decentralization technology; and strive to
									be a pioneer in the development of WEB 3."
								</p>
								<div className='position-absolute start-50 bottom-0 mb-3 '>
									<a
										href='https://twitter.com/Nitsuj_82'
										target='_blank'
									>
										<i className='text-black fab fa-twitter fa-2x'></i>
									</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='border-0 bg__primary h-100'>
							<img
								src={Yoni}
								alt='Yoni'
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									Yoni Menash (41)
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									Founder
								</h3>
								<h4 className='text-nowrap Mont-Regular mt-3 text-center'>
									Richmond Hill, Ontario CA
								</h4>
								<br />
								<p className='mb-5'>
									Global specialist in merger acquisitions and
									operations coordinator; crypto and stock
									analyst and strategist.
									<br />
									<br />
									"I was the Senior Operations Manager for the
									largest marijuana company in the world. I
									handled all logistics and operations and
									helped build the company from ground up on a
									global scale.
									<br />
									<br />
									(www.instadosepharma.com)
									<br />
									<br />I also aspire to improve living
									conditions worldwide with new renewable
									energy technology solutions. I want to be
									apart of blockchain devolvement and its
									importance in the future and the progress of
									decentralization. "
								</p>
								<div className='position-absolute start-50 bottom-0 mb-3 '>
									<a
										href='https://www.linkedin.com/in/yoni-menash-1a38b9168'
										target='_blank'
									>
										<i className='text-black fab fa-linkedin fa-2x'></i>
									</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='border-0 bg__primary h-100'>
							<img
								src={Steve}
								alt='Steve'
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									Steve M. YAPER (42)
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									Admin/Community Manager
								</h3>
								<h4 className='text-nowrap Mont-Regular mt-3 text-center'>
									Los Angeles, California USA
								</h4>
								<br />
								<p className='mb-5'>
									Early crypto investor turned NFT enthusiast.
									A specialist in SEO internet marketing,
									social media marketing and brand reputation
									building.
									<br />
									<br />
									“I come from a background in online magazine
									publishing and brand recognition
									development.
									<br />
									<br /> I have experience in managing teams
									and online community building, working with
									various NFT projects. <br />
									<br /> I'm a firm believer in the future
									development of WEB 3.”
								</p>
								<div className='position-absolute start-45 bottom-0 mb-3 '>
									<a
										href='https://twitter.com/Nuggsog'
										target='_blank'
									>
										<i className='text-black fab fa-twitter fa-2x'></i>
									</a>
									<a
										href='https://www.instagram.com/Nuggsog/'
										target='_blank'
									>
										<i className='text-black fab fa-instagram ms-2 fa-2x'></i>
									</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='bg__primary border-0 h-100'>
							<img
								src={Kings}
								alt='Kings'
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									KINGS aka HKUSASTOCK (33)
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									International Admin
								</h3>
								<h4 className='text-nowrap Mont-Regular mt-3 text-center'>
									Quebec, Canadian
								</h4>
								<br />
								<p className='mb-5'>
									Early crypto and NFT enthusiast; crypto
									research analyst, manager of a crypto
									private club, an OTC dealer and early
									investor. <br /> <br /> “I'm a professional
									trader who has been involved in the stock &
									crypto market for 8+ years.”
								</p>
								<div className='position-absolute start-40 bottom-0 mb-3 '>
									<a
										href='https://twitter.com/Hkusastockkings'
										target='_blank'
									>
										<i className='text-black fab fa-twitter fa-2x'></i>
									</a>
									<a
										href='https://www.instagram.com/Hkusastock/'
										target='_blank'
									>
										<i className='text-black fab fa-instagram ms-2 fa-2x'></i>
									</a>
									<a
										href='http://t.me/hkusastock'
										target='_blank'
									>
										<i className='text-black fab fa-telegram ms-2 fa-2x'></i>
									</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='border-0 bg__primary h-100'>
							<img
								src={Metaspark}
								alt='Metaspark'
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									Metaspark (31)
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									Admin
								</h3>
								<p className='mb-5'>
									Early crypto and NFT enthusiast; I have been
									in the industries of engineering, consulting
									and blockchain development.
									<br />
									<br /> “I believe digital ownership will be
									valued more than physical ownership in the
									future. I aspire to be a pioneer in the Web
									3.0 movement.”
								</p>
								<div className='position-absolute start-45 bottom-0 mb-3 '>
									<a
										href='https://twitter.com/MetasparkNFT'
										target='_blank'
									>
										<i className='text-black fab fa-twitter fa-2x'></i>
									</a>
									<a
										href='https://www.instagram.com/MetasparkNFT/'
										target='_blank'
									></a>
									<i className='text-black fab fa-instagram ms-2 fa-2x'></i>
								</div>
							</div>
						</Card>
					</Col>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='border-0 bg__primary h-100'>
							<img
								src={Evol}
								alt='EVOL'
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									EVOL
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									Community Lead
								</h3>
								<p className='mb-5'>
									Early crypto investor turned NFT collector.
									<br />
									<br />
									"I have been successfully trading
									cryptocurrencies since 2017 and started my
									journey as an investor in the NFT market in
									2019. <br /> <br /> I graduated with an
									associates degree in computer science in
									2012 and I enjoy doing graphic design in my
									free time. <br />
									<br /> I'm a firm believer in the blockchain
									and its importance in the future and will
									continue to fight for the progress of
									decentralization.."
								</p>
								<div className='position-absolute start-45 bottom-0 mb-3 '>
									<a
										href='https://twitter.com/Evolotpyrc'
										target='_blank'
									>
										<i className='text-black fab fa-twitter fa-2x'></i>
									</a>
									<a
										href='https://www.instagram.com/EvolAlex1/'
										target='_blank'
									>
										<i className='text-black fab fa-instagram ms-2 fa-2x'></i>
									</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='border-0 bg__primary h-100'>
							<img
								src={Ryu}
								alt='Ryu '
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									Ryu
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									Lead Mod
								</h3>
								<p className='mb-5'>
									"I'm a crypto & NFT enthusiast; been in the
									space for 3 years. I've worked as a
									community manager and moderator for 20+
									successful NFT projects.
									<br />
									<br /> I'm a big believer and supporter in
									WEB 3.0."
								</p>
								<div className='position-absolute start-50 bottom-0 mb-3 '>
									<a
										href='https://twitter.com/Degen_ryu'
										target='_blank'
									>
										<i className='text-black fab fa-twitter fa-2x'></i>
									</a>
								</div>
							</div>
						</Card>
					</Col>
					<Col xl='4' lg='6' className='mt-4 team__card'>
						<Card className='h-100 border-0 bg__primary'>
							<img
								src={Childsy}
								alt='Childsy '
								className='img-fluid border2'
							/>
							<div className='hover__info'>
								<h1 className='text-nowrap Bangers text-center'>
									Childsy
								</h1>
								<h3 className='text-nowrap Bad-Script mt-3 text-center'>
									Lead Mod
								</h3>
								<p className='mb-5'>
									"I'm a crypto and NFT enthusiast who has
									been involved in many NFT projects.
									<br />
									<br /> I'm passionate and want to contribute
									to the web 3.0 development"
								</p>
								<div className='position-absolute start-50 bottom-0 mb-3 '>
									<a
										href='https://twitter.com/Notchildsy'
										target='_blank'
									>
										<i className='text-black fab fa-twitter fa-2x'></i>
									</a>
								</div>
							</div>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default TheTeam;
