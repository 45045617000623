import React from 'react';
import { Container } from 'reactstrap';
import Bape1 from '../assets/imgs/bape1.png';
import Bape2 from '../assets/imgs/bape2.png';
import Bape3 from '../assets/imgs/bape3.png';
import Bape4 from '../assets/imgs/bape4.png';
import Bape6 from '../assets/imgs/bape6.jpg';
import Bape8 from '../assets/imgs/bape8.jpg';
import Bape9 from '../assets/imgs/bape9.png';
import Bape10 from '../assets/imgs/bape10.jpg';
import Bape11 from '../assets/imgs/bape11.jpg';
import Bape12 from '../assets/imgs/bape12.jpg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 2500 },
		items: 4,
	},
	desktop: {
		breakpoint: { max: 2500, min: 1400 },
		items: 3,
	},
	medium: {
		breakpoint: { max: 1400, min: 992 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 992, min: 645 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 645, min: 0 },
		items: 1,
	},
};
function UniqueBapes() {
	return (
		<div className='unique__bapes'>
			<Container className='pb-5 mx-0'>
				<div className='d-flex justify-content-center'>
					<h2 className='text__primary Bangers unique__number'>
						8,888{' '}
					</h2>{' '}
					<h2 className='text-white ms-3 ms-md-5 text-nowrap Bad-Script unique__text mt-0 mt-lg-5'>
						{' '}
						Unique Bapes
					</h2>
				</div>
				<Carousel
					className=''
					responsive={responsive}
					autoPlay
					infinite
					showDots
					removeArrowOnDeviceType={['desktop', 'tablet', 'mobile']}
				>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape1} alt='Bape1' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape2} alt='Bape2' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape6} alt='Bape6' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape3} alt='Bape3' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape4} alt='Bape4' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape8} alt='Bape8' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape10} alt='Bape10' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape9} alt='Bape9' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape11} alt='Bape11' />
					</div>
					<div
						className='bape__card'
						style={{ marginLeft: 'auto', marginRight: 'auto' }}
					>
						<img className='img-fluid' src={Bape12} alt='Bape12' />
					</div>
				</Carousel>
			</Container>
		</div>
	);
}

export default UniqueBapes;
